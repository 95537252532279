<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ taskType }}</h1>
      <div class="btns-container">
        <VasionButton
          id="actions"
          name="actions"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="handleNewClick()"
        >
          New {{ taskTypeString }}
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="main-table-container vasion-html-table-default">
        <div v-if="tableData && tableData.Rows">
          <VasionTable
            id="all-workflows-table"
            :headerColumns="tableData.Columns.Values"
            :tableRows="tableData.Rows.Values"
            :hideColumns="hiddenColumns"
            :ellipsisButtonConfig="ellipsisButtonConfig"
            :maxTextLength="tableTextLength"
            @ellipsis-button-clicked="ellipsisButtonClicked"
            @vasion-selection="rowClickHandler"
          />
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="vasion-new-or-edit-task-div"
      :confirmButtonText="taskId === 0 ? 'Create' : 'Save'"
      :rejectButtonText="'Cancel'"
      :modalType="'slot'"
      :buttonGroup="'end'"
      :modalTitle="taskId === 0 ? 'New Task' : 'Edit Task'"
      :confirmButtonDisabled="taskTextBody.length === 0"
      :sync="showNewOrEditTaskDialog"
      @confirmButtonClick="saveTask()"
      @noButtonClick="cancelTask()"
    >
      <div class="task-dialog">
        <div>
          <div class="textarea-title" :class="{ 'error-task': errorTask }">
            TASK TEXT
          </div>
          <textarea
            id="task-textarea"
            v-model="taskTextBody"
            label="Task"
            class="vasion-textarea"
            :class="{ 'error-task': errorTask }"
            placeholder="Enter text..."
            rows="10"
          />
        </div>
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="vasion-new-or-edit-tasklist-div"
      :confirmButtonText="taskId === 0 ? 'Create' : 'Save'"
      :rejectButtonText="'Cancel'"
      :modalType="'slot'"
      :buttonGroup="'end'"
      :modalTitle="taskId === 0 ? 'New Tasklist' : 'Edit Tasklist'"
      :confirmButtonDisabled="disableTasklistConfirm"
      :sync="showNewOrEditTaskListDialog"
      @confirmButtonClick="saveTask()"
      @noButtonClick="cancelTask()"
    >
      <div class="new-tasklist-dialog">
        <VasionInput
          id="tasklist-name"
          v-model="tasklistName"
          title="TASKLIST NAME"
          name="tasklist-name"
          placeholder="Enter Name..."
          inputType="top-white"
          :isInErrorState="errorTasklist"
        />

        <div v-if="workflowTasks && workflowTasks.length > 0" class="tasklist-modal-table vasion-html-table-default">
          <table>
            <thead class="whiteBackground">
              <tr>
                <th class="checkbox-column vasion-html-table-header"></th>
                <th class="vasion-html-table-header">
                  <label>Task Name</label>
                </th>
              </tr>
            </thead>
            <tbody class="tasklist-body">
              <tr
                v-for="(task, index) in workflowTasks"
                :key="index"
                :class="{'vasion-lightest-grey-background': index % 2 === 0}"
              >
                <td class="checkbox-column">
                  <VasionCheckbox
                    :id="`chkRow_${task.iID}`"
                    :ref="`chkRow_${task.iID}`"
                    :checked="checkedTasks.includes(task.iID)"
                    @change="toggleCheckedRow(task.iID)"
                  />
                </td>
                <td class="vasion-html-table-field fullWidth">
                  <label> {{ task.sName }} </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="vasion-delete-div"
      :confirmButtonText="'Yes'"
      :rejectButtonText="'No'"
      :buttonGroup="'end'"
      :modalType="'message'"
      :modalTitle="`Delete ${taskTypeString}`"
      :message="taskType === 'Tasks' ? `Are you sure you want to delete: ${taskTextBody}` : `Are you sure you want to delete: ${tasklistName}`"
      :sync="showDeleteDialog"
      @confirmButtonClick="deleteTask()"
      @noButtonClick="showDeleteDialog = !showDeleteDialog"
    />

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
export default {
  name: 'WorkflowTasks',
  data: function () {
    return {
      checkedTasks: [],
      columns: {
        Values: [
            '_WorkflowID_',
            'Task Name',
            '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      errorTask: false,
      errorTasklist: false,
      hiddenColumns: ['_WorkflowID_'],
      showDeleteDialog: false,
      showNewOrEditTaskDialog: false,
      showNewOrEditTaskListDialog: false,
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {},
      taskId: 0,
      tasklistName: '',
      taskTextBody: '',
      taskTypeString: '',
      windowWidth: 0,
      workflowTasks: null,
    }
  },
  computed: {
    disableTasklistConfirm() { return this.checkedTasks.length < 1 || this.tasklistName.length < 1 },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    taskType() { return this.$store.state.workflow.workflowTask },
    tableTextLength() { return this.windowWidth > 948 ? 'calc(100vw - 400px)' : 'calc(100vw - 100px)' },
  },
  async created() {
    await this.$store.dispatch('security/setEllipsisButtonConfig', this.taskType.toLowerCase())
    this.getWorkflowTasks()
    this.taskTypeString = this.taskType === 'Tasks' ? 'Task' : 'Tasklist'
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth()
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    buildTableData(workflowTasks) {
      const data = !workflowTasks ? [] : workflowTasks.map((task) => {
        return {
          Values: [
            task.iID,
            task.sName,
            'VasionEllipsisIcon',
          ],
          ValueType: 'String',
        }
      })

      this.columns.Values[1] = `${this.taskTypeString} Name`

      this.tableData = {
        Columns: this.columns,
        Rows: {
          Values: data,
          ValueType: 'ApiArray`1',
        },
      }
    },
    cancelTask() {
      if (this.taskType === 'Tasks') {
        this.toggleTaskDialog()
      } else if (this.taskType === 'Tasklist') {
        this.toggleTasklistDialog()
      }
    },
    async deleteTask() {
      let response = null
      if (this.taskType === 'Tasks' && this.taskId !== 0) {
        response = await this.$store.dispatch('workflow/deleteTemplateTask', this.taskId)
      } else if (this.taskType === 'Tasklist' && this.taskId !== 0) {
        response = await this.$store.dispatch('workflow/deleteTaskList', this.taskId)
      }

      if (response === 'True') {
        this.showDeleteDialog = false
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = `${this.taskTypeString} Deleted`
        this.snackbarImage = true
        this.showSnackbar = true

        this.getWorkflowTasks()
      } else {
        this.showDeleteDialog = false
        this.snackbarTitle = 'Failure'
        this.snackbarSubTitle = `${this.taskTypeString} Not Deleted`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-task-button':
            this.showEditDialog(id)
            break;
          case 'delete-task-button':
            this.showTheDeleteDialog(id)
            break;
          case 'edit-tasklist-button':
            this.showEditDialog(id)
            break
          case 'delete-tasklist-button':
            this.showTheDeleteDialog(id)
            break
          default:
            break;
        }
      }
    },
    async getWorkflowTasks() {
      if (this.taskType === 'Tasks') {
        this.workflowTasks = await this.$store.dispatch('workflow/getAllTemplateTasks')
      } else if (this.taskType === 'Tasklist') {
        this.workflowTasks = await this.$store.dispatch('workflow/getAllTaskLists')
      }
      await this.buildTableData(this.workflowTasks)
    },
    async handleNewClick() {
      if (this.taskType === 'Tasks') {
        this.toggleTaskDialog()
      } else if (this.taskType === 'Tasklist') {
        this.workflowTasks = await this.$store.dispatch('workflow/getAllTemplateTasks')
        this.toggleTasklistDialog()
      }
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.showEditDialog(data.Values[0])
    },
    async saveTask() {
      let endpoint = ''
      let payload = {}

      if (this.taskType === 'Tasks') {
        if (this.taskTextBody.trim() === '') {
          this.errorTask = true
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = 'Task cannot only include spaces.'
          this.snackbarImage = false
          this.showSnackbar = true
          return
        }

        payload = {
          taskTemplateID: this.taskId,
          taskText: this.taskTextBody,
        }
        endpoint = 'saveTemplateTask'
      } else if (this.taskType === 'Tasklist') {
        if (this.tasklistName.trim() === '') {
          this.errorTasklist = true
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = 'Tasklist Name cannot only include spaces.'
          this.snackbarImage = false
          this.showSnackbar = true
          return
        }

        payload = {
          taskListID: this.taskId,
          taskListName: this.tasklistName,
          orderedTaskTemplateIDs: this.checkedTasks,
        }
        endpoint = 'saveTaskList'
      }

      const response = await this.$store.dispatch(`workflow/${endpoint}`, payload)
      if (response && response !== 0) {
        this.snackbarTitle = 'Success'
        if (this.taskId === 0) {
          this.snackbarSubTitle = `New ${this.taskTypeString} created successfully`
        } else {
          this.snackbarSubTitle = `${this.taskTypeString} edited successfully`
        }
        this.snackbarImage = true
        this.showSnackbar = true
        this.getWorkflowTasks()

        if (this.taskType === 'Tasks') {
          this.toggleTaskDialog()
        } else if (this.taskType === 'Tasklist') {
          this.toggleTasklistDialog()
        }
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = this.taskId === 0 ? `There was an error creating the ${this.taskTypeString}` : `There was an error editing the ${this.taskTypeString}`
        this.snackbarImage = false
        this.showSnackbar = true
      }
    },
    async showEditDialog(taskId) {
      if (this.taskType === 'Tasks') {
        this.taskTextBody = await this.$store.dispatch('workflow/getTemplateTaskText', taskId)

        if (this.taskTextBody && this.taskTextBody !== '') {
          this.showNewOrEditTaskDialog = true
          this.taskId = taskId
        } else {
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = `Something went wrong when loading the ${this.taskTypeString} details to edit`
          this.snackbarImage = false
          this.showSnackbar = true
        }
      } else if (this.taskType === 'Tasklist') {
        const promises = await Promise.all([
          this.$store.dispatch('workflow/getAllTemplateTasks'),
          this.$store.dispatch('workflow/getTaskListWithData', taskId),
        ])
        // eslint-disable-next-line prefer-destructuring
        this.workflowTasks = promises[0]
        const [, payload] = promises

        if (payload && payload !== undefined) {
          this.checkedTasks = payload.orderedTaskTemplateIDs
          this.taskId = taskId
          this.tasklistName = payload.taskListName
          this.showNewOrEditTaskListDialog = true
        } else {
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = `Something went wrong when loading the ${this.taskTypeString} details to edit`
          this.snackbarImage = false
          this.showSnackbar = true
        }
      }
    },
    async showTheDeleteDialog(taskId) {
      if (this.taskType === 'Tasks') {
        this.taskTextBody = await this.$store.dispatch('workflow/getTemplateTaskText', taskId)

        if (this.taskTextBody && this.taskTextBody !== '') {
          this.showDeleteDialog = true
          this.taskId = taskId
        } else {
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = `Something went wrong when loading the ${this.taskTypeString} details to delete`
          this.snackbarImage = false
          this.showSnackbar = true
        }
      } else if (this.taskType === 'Tasklist') {
        const payload = await this.$store.dispatch('workflow/getTaskListWithData', taskId)
        if (payload && payload !== undefined) {
          this.showDeleteDialog = true
          this.checkedTasks = payload.orderedTaskTemplateIDs
          this.taskId = taskId
          this.tasklistName = payload.taskListName
        } else {
          this.snackbarTitle = 'Error'
          this.snackbarSubTitle = `Something went wrong when loading the ${this.taskTypeString} details to delete`
          this.snackbarImage = false
          this.showSnackbar = true
        }
      }
    },
    toggleCheckedRow(item) {
      const indexOf = this.checkedTasks.indexOf(item)

      if (indexOf >= 0) {
        this.checkedTasks.splice(indexOf, 1)
      } else {
        this.checkedTasks.push(item)
      }
    },
    toggleTaskDialog() {
      this.showNewOrEditTaskDialog = !this.showNewOrEditTaskDialog
      this.taskId = 0
      this.taskTextBody = ''
      this.errorTask = false
    },
    toggleTasklistDialog() {
      this.showNewOrEditTaskListDialog = !this.showNewOrEditTaskListDialog
      this.checkedTasks = []
      this.taskId = 0
      this.tasklistName = ''
      this.errorTasklist = false
    },
    updateWindowWidth() { this.windowWidth = window.innerWidth },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

.grid-div {
  max-height: calc(100vh - 224px);
  overflow: auto;
}
.task-dialog {
  width: 928px;
  border-radius: 8px;
  padding: 20px 0px;
}
.new-tasklist-dialog {
  width: 100%;
  max-height: calc(100vh - 200px);
  padding: 20px 0px;
  overflow: hidden;

  @media (min-width: $breakpoint-md) {
    min-width: 600px;
  }

  @media (min-width: $breakpoint-lg) {
    max-height: calc(100vh - 400px);
    min-width: 900px;
  }
}
.textarea-title {
  height: 12px;
  font-size: 12px;
  font-weight: 500;
  color: $grey-400;
  margin-bottom: 15px;
}
#task-textarea {
  background-color: $white;
  border-color: $grey-100;
}
.tasklist-modal-table {
  margin: 10px 0px;
}
.checkbox-column {
  width: 60px;
  padding: 0 15px;
}
.tasklist-body {
  display: table-caption;
  caption-side: bottom;
  overflow: auto;
  max-height: calc(100vh - 344px);

  @media (min-width: $breakpoint-lg) {
    max-height: calc(100vh - 544px);
  }
}
.whiteBackground {
  background-color: $white;
}
.fullWidth {
  width: 100%;
}
.error-task {
  border-color: $error-red !important;
  color: $error-red !important;
}
</style>
